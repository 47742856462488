import React, { useEffect, useRef, useContext } from "react"
import { SiteContext } from "@context/siteContext"

import * as styles from "./trophy-view.module.scss"

export default function NationsLegaueTrophyPage() {
  const ref = useRef()

  const { actions } = useContext(SiteContext)
  useEffect(() => {
    import("@google/model-viewer")

    ref.current.addEventListener("load", () => {
      actions.setReady(true)
    })
  }, [])
  return (
    <div className={styles.base}>
      <model-viewer
        ref={ref}
        src="/models/nations-league.glb"
        ios-src="/models/nations-league.usdz"
        alt="A 3D model of the CONCACAF Nations’ League trophy"
        ar
        ar-modes="webxr scene-viewer quick-look"
        environment-image="neutral"
        auto-rotate
        camera-controls
      />
    </div>
  )
}
